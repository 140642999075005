import React, { Component, Suspense} from 'react';

// import './css/colors.css';
// import './css/custom.css';

import { Route, BrowserRouter, Switch } from 'react-router-dom'
// import { Router, Route, browserHistory } from 'react-router';
// import App from "./App";
// import TransferTicket from "./views/tickets/transfer";
// import Error404 from "./views/error";
// import AxsTicket from "./views/vbd-data";
// import Fingerprint from "./Fingerprint";
// import TMUKTicket from "./views/TMUK"
import Loader from "react-loader-spinner";
import { Spinner } from "react-bootstrap"
const Error404 = React.lazy(() =>
    import("./views/error")
);
const App = React.lazy(() =>
    import("./App")
);
const AxsTicket = React.lazy(() =>
    import("./views/axs")
);
const TMUKTicket = React.lazy(() =>
    import("./views/TMUK")
);
const SeatGeek = React.lazy(() =>
    import("./views/Seatgeek")
);
export default class Routing extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Suspense fallback={
               <>
                {/* <div style={{ textAlign: "center", padding: 0, marginTop: 20 }}>
                  <a href={`https://verified-tickets.com${window.location.pathname}`}>View Ticket (for slow connections)</a>
                </div> */}

                <Loader
                  type="TailSpin"
                  color={"rgb(10, 34, 64)"}
                  height={100}
                  width={100}
                  timeout={10000}
                  style={{ textAlign: "center", padding: 0, marginTop: "200px" }}
                />

              </>
                // <div className="loader-hpZbgI">
                //     <Spinner animation="border" role="status">
                //         <span className="sr-only">Loading...</span>
                //     </Spinner>
                // </div>
            }
            
            >
                <BrowserRouter>
                    <Switch>
                        {/* <Route exact path="/save-fingerprint" component={Fingerprint}/> */}
                        <Route exact path="/:gameID?" component={App} />
                        <Route exact path="/axs/:ticketURL" component={AxsTicket} />
                        <Route exact path="/tmuk/:uniquekey" component={TMUKTicket} />
                        <Route exact path="/seatgeek/:uniquekey" component={SeatGeek} />
                        {/* <Route exact path="/:gameID?" component={App}/> */}
                        <Route path="/game/error" component={Error404} />
                        {/*<Route path="/game/transfer" component={TransferTicket}/>*/}
                    </Switch>
                </BrowserRouter>
            </Suspense>
        )
    }
};

